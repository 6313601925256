//@import "~bootstrap/scss/bootstrap-reboot";
//@import "~bootstrap/scss/bootstrap-grid";
@import "scss/lib/bootstrap-grid";
@import url("~@angular/material/prebuilt-themes/indigo-pink.css");


body {
  height: 100vh;
}

header {
  padding: 10px;

  .logo {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 30vw;
    height: 100px;
  }
}

.mat-icon.menu-icon {
  height: 48px;
  width: 48px;
  font-size: 48px;
}

.menu {
  padding: 10px;
  min-width: 20vw;
  height: 100vh;
  font-size: 1.5em;
}

h1 {}

h2 {}

h3 {}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.clickable {
  cursor: pointer;
}

.inline-block,
.mat-mdc-progress-spinner.inline-block {
  display: inline-block;
}

.fullwidth {
  width: 100%;
}

.section {
  margin: 20px 0px;
  background: rgba(255, 255, 255, .1);
  padding: 10px;
}

a:active,
a:focus-visible,
a:focus-within{
  border:0px;
  outline: 0px;
}
